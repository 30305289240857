.delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;

  &__text {
    margin-left: 15px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}
