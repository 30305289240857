@use 'sass:map';
@import "../../styles/variables.scss";

.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    .checkbox__wrap {
      background-color: map-get($primary, "200");
    }
  }

  &__wrap {
    background-color: map-get($primary, "300");
    width: 20px;
    height: 20px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__control:checked + .checkbox__decor {
    opacity: 1;
  }

  &__decor {
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 3px;
    opacity: 0;

    transition: opacity 0.2s linear;
  }

  &__label {
    margin-left: 10px;
    font-size: 18px;
  }
}
