.search {
  display: flex;
  align-items: center;

  &__input {
    min-width: 250px;
  }

  &__delete {
    margin-top: 15px;
  }
}
