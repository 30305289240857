.ios-edit {
  padding: 15px;

  .input {
    & + .input {
      margin-top: 15px;
    }
  }

  &__submit {
    margin-top: 15px;
    width: 100%;
  }
}
