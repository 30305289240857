.ftp-list {
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;

  &__item {
    list-style: none;
    padding: 20px;
    display: flex;
    align-items: center;

    .delete-button {
      margin-right: 20%;
    }
  }
}
