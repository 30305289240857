@use 'sass:map';
@import '../../styles/variables.scss';

.button {
  background-color: map-get($primary, '300');
  color: map-get($light, '100');
  font-weight: 500;
  padding: 7px 15px;
  border-radius: 5px;
  font-size: 13px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: map-get($primary, '200');
  }

  &--disabled {
    background-color: map-get($primary, '100');
  }

  &--dangers {
    background-color: $error;

    &:hover {
      background-color: #ce5151;
    }
  }
}
