.domains {
  .domain {
    padding: 10px 0;
    overflow: hidden;
    max-height: 0;

    &--open {
      max-height: none;
    }
  }

  .domain-toggler {
    margin-left: auto;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    margin-left: 40px;

    & > * {
      margin-left: 10px;
    }
  }

  &__head {
    display: flex;
    margin: 15px 0;

    & > div {
      flex: 1;
      border-radius: 4px;
      box-shadow: 0 0 3px #ccc;
      text-align: center;

      &:first-child {
        max-width: 50px;
      }

      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }

  &__list {
    margin-top: 20px;

    &-title {
      background-color: rgb(216, 216, 216);
      color: rgb(0, 14, 44);
      padding: 10px;
      border-radius: 5px;
      margin: 20px 0 10px 60px;
      display: inline-block;
      text-transform: capitalize;
    }

    &-head {
      display: flex;
      align-items: center;

      form {
        display: flex;
        align-items: flex-end;
        margin-left: 15px;
      }
    }

    &-item {
      display: flex;
      margin-bottom: 15px;

      form {
        display: flex;
        align-items: center;

        .input {
          padding: 0;
        }
      }

      & > div {
        flex: 1;
        border-radius: 4px;
        box-shadow: 0 0 3px #ccc;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        &:first-child {
          max-width: 50px;
        }

        &:not(:first-child) {
          margin-left: 10px;
        }

        &.button-cell {
          box-shadow: none;
        }
      }

      &-activated {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: red;

        &--true {
          background-color: #00be00;
        }
      }
    }
  }

  &__button {
    white-space: nowrap;
  }
}

.domain-zones {
  width: calc(100% - 100px);
  margin: 0 0 30px 50px;
  border: 1px solid #bbb;
  border-radius: 5px;
  padding: 15px;

  &__list {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0 0 10px;
      border: 1px solid #ccc;
      padding: 10px;
      border-radius: 5px;

      &-name {
        font-size: 18px;
      }

      &-button {
        margin-left: 10px;
      }
    }
  }
}

.geo-form {
  display: flex;
  width: 100%;

  .button {
    margin-left: 15px;
  }
}
