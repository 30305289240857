.pwag-translates {
  &__list {
    margin-top: 20px;

    &-head {
      display: flex;

      & > div {
        flex: 1;
        text-align: center;
        word-break: break-word;
      }
    }

    &-body {
      & > a,
      & > div {
        display: flex;
        align-items: center;
        box-shadow: 0 0 3px #bbb;
        width: 100%;
        margin-top: 20px;
        padding: 10px 0;

        & > div {
          flex: 1;
          text-align: center;
        }

        .affilates__list-body-counter {
          display: flex;

          & > div {
            padding-left: 10px;

            & + div {
              border-left: 2px solid #ccc;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

.add-pwag-translate {
  width: 1000px !important;

  &__form {
    padding: 20px;

    &-submit {
      margin-top: 10px;
    }
  }
}
