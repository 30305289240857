.preland-list {
  overflow: hidden;

  &__head {
    display: flex;
    border-bottom: 1px solid #777777;

    & > * {
      flex: 1;
      font-size: 14px;
      font-weight: 700;
      padding: 5px;
      text-align: center;
    }
  }

  &__list {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 65vh;
  }

  &__item {
    display: flex;
    border-bottom: 1px solid #777777;

    & > * {
      flex: 1;
      font-size: 16px;
      padding: 5px;
      text-align: center;
    }
  }
}
