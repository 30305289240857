.help {
  &__list {
    max-width: 1250px;

    &-item {
      & + .help__list-item {
        margin-top: 15px;
        border-top: 2px solid #f0f0f0;
        padding-top: 10px;
      }

      &-title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 15px;
      }

      &-body {
        white-space: pre-wrap;
      }
    }
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    &-item {
      display: block;
      margin-top: 10px;

      & + .help__links-item {
        margin-left: 15px;
      }
    }
  }
}
