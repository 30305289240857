.bot-text {
  &__table {
    &-head {
      display: flex;
      margin-bottom: 15px;

      & > div {
        border-radius: 4px;
        box-shadow: 0 0 3px #ccc;
        text-align: center;

        &:not(:first-child) {
          margin-left: 10px;
        }

        flex: 1;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
    }

    &-item {
      display: flex;
      margin-bottom: 15px;

      & > div {
        flex: 1;
        border-radius: 4px;
        box-shadow: 0 0 3px #ccc;
        text-align: center;

        &:not(:first-child) {
          margin-left: 10px;
        }
      }
      .bot-text__table-button {
        box-shadow: none;
      }
    }
  }

  button {
    background-color: rgb(152, 152, 221);
    border: none;
    border-radius: 4px;
    color: #fff;
    padding: 5px 15px;
    transition: all 0.3s linear;
    font-size: 12px;
    margin-left: 10px;
  }
}

.edit-bot-text {
  display: flex;
  flex-direction: column;
  padding: 20px;

  button {
    background-color: rgb(152, 152, 221);
    border: none;
    border-radius: 4px;
    color: #fff;
    padding: 5px 15px;
    transition: all 0.3s linear;
    font-size: 12px;
  }

  textarea {
  }
}
