.locations-item {
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 0 2px #ccc;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-end;

  &__select {
    width: 300px;
    margin: 0 30px;
  }

  .delete-button {
    margin: 0 0 10px 10px;
  }
}
