.profit {
  &__head {
    display: flex;
    margin-top: 15px;

    &-item {
      flex: 1;
      padding: 10px;
      text-align: center;
      border-radius: 6px;
      -webkit-box-shadow: 0px 1px 6px 2px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 0px 1px 6px 2px rgba(34, 60, 80, 0.2);
      box-shadow: 0px 1px 6px 2px rgba(34, 60, 80, 0.2);

      & + .profit__head-item {
        margin-left: 15px;
      }
    }
  }

  &__table {
    &-item {
      display: flex;
      margin-top: 15px;
    }

    &-cell {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-box-shadow: 0px 1px 6px 2px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 0px 1px 6px 2px rgba(34, 60, 80, 0.2);
      box-shadow: 0px 1px 6px 2px rgba(34, 60, 80, 0.2);

      & + .profit__table-cell {
        margin-left: 15px;
      }
    }
  }
}
