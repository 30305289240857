.errors-info {
  &__table {
    &-head {
      display: flex;

      & > div {
        padding: 10px;

        flex: 1;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
    }

    &-item {
      display: flex;
      padding: 10px;
      box-shadow: 0 0 3px #ccc;
      border-radius: 5px;
      margin-bottom: 10px;

      & > div {
        flex: 1;
      }
    }
  }
}
