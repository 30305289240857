.collapsed-cell {
  position: relative;
  overflow: visible;

  &:hover {
    .collapsed-cell__value {
      opacity: 1;
      visibility: visible;
    }
  }

  &__preview {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
    padding: 0 10px;
    font-size: 14px;
  }

  &__value {
    width: 300px;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    font-size: 16px;
    transition: opacity 0.3s linear;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0 0 3px #ccc;
    border-radius: 4px;
    padding: 5px;
    white-space: pre-wrap;
    word-break: break-all;

    z-index: 20;
  }
}
