@use 'sass:map';
@import '../../styles/variables.scss';

.current-pwag {
  .avatar {
    max-width: 200px;
    max-height: 100px;
    margin-bottom: 15px;
    display: flex;

    h2 {
      font-size: 12px;
    }

    svg {
      width: 20px;
      height: 45px;
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
  }

  &__language {
    min-width: 200px;
  }

  &__controls {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 0 -20px;
  }

  &__toggle-images {
    width: 100%;
    padding: 15px;
    font-size: 18px;
    margin-top: 15px;
  }

  &__title {
    margin: 48px 0 0 0;
    font-size: 18px;
    text-align: center;
  }

  &__one-signal {
    margin: 16px 0 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;

    &-title {
      font-size: 14px;
      margin: 0 0 8px 0;
    }

    &-row {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0 0 48px 0 ;

      &-name {
        font-size: 14px;
        font-weight: 600;
        margin: 0 12px 0 0;
      }

      .input {
        margin: 16px 0 0 0 !important;
        flex: auto !important;
        padding: 0 !important;
      }
    }
  }

  .form-color-picker,
  &__select,
  .input {
    flex: 1 1 20%;
    margin-left: 20px;
    align-self: center;

    &:nth-child(n + 5) {
      margin-top: 20px;
    }
  }
  .form-color-picker {
    .input {
      margin: 0;
    }

    .sketch-picker {
      position: absolute;
      z-index: 100;
    }
  }

  &__checkbox {
    margin: 20px 0;
    width: 100px;
  }

  textarea.control {
    min-height: 70px;
  }

  &__array-fields {
    display: flex;

    &--vertical {
      flex-direction: column;
    }
  }

  &__icon {
    align-self: flex-start;
    max-width: 180px;

    .image__current {
      width: 180px;
      min-height: 180px;
    }
  }

  &__gallery {
    margin: 20px 0 0 0 !important;

    .image__current {
      max-width: 400px;
      max-height: 300px;
    }
  }
}

.current-pwag-field-array {
  flex: 1;
  padding: 20px 0;

  .right-buttons {
    display: flex;
    flex-direction: column;
    padding: 10px;

    .priority {
      margin-top: 25px;
      flex: 1;
      min-height: 100px;
      box-shadow: 0 0 0 1px #ccc;
      border-radius: 5px;
      cursor: pointer;
      position: relative;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        width: 20px;
        height: 2px;
        display: block;
        background-color: #000;
      }

      &--up {
        &::before {
          left: 66%;
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &::after {
          left: 34%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }

      &--down {
        &::before {
          left: 66%;
          transform: translate(-50%, -50%) rotate(135deg);
        }

        &::after {
          left: 34%;
          transform: translate(-50%, -50%) rotate(-135deg);
        }
      }

      &--disabled {
        background-color: #ccc;
        pointer-events: none;
      }
    }
  }

  &__geos-filter {
    margin-bottom: 20px;
    width: calc(100% - 40px);
  }

  &__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;

    &-controls {
      width: 100%;
    }

    .input {
      margin-left: 0;

      & + .input {
        margin: 10px 0 0 0;
      }
    }

    button {
      background-color: transparent;
      border: none;
      margin: 20px 0 0 10px;
    }
  }

  .button {
    margin-top: 20px;
  }
}

.keys {
  flex: 1;
  padding: 20px 0;
  margin-left: 20px;

  &__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;

    &-controls {
      width: 100%;
    }

    .input {
      margin: 0 0 10px 0;
    }

    button {
      background-color: transparent;
      border: none;
      margin: 20px 0 0 10px;
    }
  }
}

.image {
  flex: 1;
  position: relative;

  & + .image {
    margin-left: 20px;
  }

  &__current {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed map-get($dark, '300');
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      border-style: solid;
    }

    svg {
      width: 40px;
      height: 50px;

      fill: map-get($dark, '300');
    }

    img {
      width: 100%;
      height: auto;
      border-radius: 5px;
    }
  }

  &__menu {
    position: absolute;
    background-color: #fff;
    width: 600px;
    height: auto;
    max-height: 500px;
    top: -5%;
    left: 500px;
    transform: translateX(-50%);
    padding: 15px 0;
    border-radius: 5px;
    box-shadow: 0 0 2px map-get($dark, '300');
    display: flex;
    flex-direction: column;
    z-index: 1;

    &-add {
      position: fixed;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 15px);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      background-color: map-get($dark, '200');
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
      font-weight: 600;
      color: map-get($dark, '500');

      &:hover {
        background-color: map-get($dark, '300');
      }

      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        fill: map-get($dark, '500');
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      margin: 45px 0 0 -10px;
      padding: 0 10px;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: map-get($dark, '300');
        border-radius: 4px;
      }

      &-item {
        flex: 1 1 calc(100% / 4);
        max-width: calc((100% / 4) - 10px);
        margin: 10px 0 0 10px;
        cursor: pointer;

        img {
          width: 100%;
          height: auto;
          border-radius: 5px;
        }

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}

.avatar {
  width: 130px;
}
