.ping {
  &__list {
    margin-top: 20px;

    &-head {
      display: flex;

      & > div {
        flex: 1;
        text-align: center;
        word-break: break-word;
      }
    }

    &-body {
      & > a,
      & > div {
        display: flex;
        align-items: center;
        box-shadow: 0 0 3px #bbb;
        width: 100%;
        margin-top: 20px;

        & > div {
          flex: 1;
          text-align: center;
        }
      }
    }
  }
}
