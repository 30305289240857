body {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin: 0;
}
$base-font-size: 12px;
$title-font-size: 1.3em;

$primary-color-dark: #e64a19;
$primary-color: #ff5722;
$primary-color-light: #ffccbc;
$primary-color-text: #ffffff;
$accent-color: #ff5252;
$primary-text-color: #212121;
$secondary-text-color: #757575;
$divider-color: #e4e4e4;
$today-bg-color: #fff9c4;

@import "../node_modules/@y0c/react-datepicker/assets/styles/_mixin.scss";
@import "../node_modules/@y0c/react-datepicker/assets/styles/app.scss";

.main {
  padding: 15px;
}
