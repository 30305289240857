.edit-geo {
  max-height: 720px;

  &__list {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px 0;
    height: 400px;

    &-head {
      display: flex;
      border-bottom: 1px solid #ccc;

      & > div {
        flex: 1;
        text-align: center;
        padding: 5px;
      }
    }
  }

  &__item {
    cursor: pointer;
    text-align: center;
    display: flex;
    padding: 10px;

    & > div {
      flex: 1;
    }

    &-flag {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: red;
      margin: 0 auto;

      &--true {
        background-color: #00be00;
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
    padding: 10px;

    .create__geos {
      margin-top: 15px;
    }

    .input + .input {
      margin-top: 10px;
    }

    button {
      margin-top: 15px;
      border-radius: 4px;
      border: none;
      background-color: #000;
      color: #fff;
    }
  }

  .back {
    margin: 10px 0 0 10px;
    border: none;
    background-color: #000;
    color: #fff;
    border-radius: 4px;
  }
}
