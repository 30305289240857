.create {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-height: 800px;
  overflow-y: auto;
  overflow-x: hidden;

  &__row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;

    .input + .input {
      margin-left: 10px;
    }
  }

  &__input {
    box-sizing: border-box;
    width: 100%;
    padding: 5px 10px;

    &--error {
      border: 1px solid red;
      outline: none;
    }
  }

  .input-label {
    width: 100%;
    display: block;
    text-align: start;
    margin: 15px 0 0 0;
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    &-item {
      background-color: #00be00;
      color: #fff;
      border: none;
      border-radius: 5px;
      padding: 7px 35px;
      transition: all 0.3s linear;
      margin-top: 20px;
      margin-left: auto;

      &--delete {
        padding: 2px 18px;
        font-size: 13px;
        background-color: red;
        margin-left: 0;

        &:hover {
          box-shadow: 0 0 3px red;
        }
      }

      &:hover {
        box-shadow: 0 0 3px #00be00;
      }
    }
  }

  &__geos {
    width: 100%;
  }
}

.pixels {
  width: 100%;

  &__item {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .button {
    margin-top: 15px;
  }
}
