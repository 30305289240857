.offer {
  &__head {
    display: flex;
    margin-top: 15px;

    &-item {
      flex: 1;
      padding: 10px;
      text-align: center;
      border-radius: 6px;
      -webkit-box-shadow: 0px 1px 6px 2px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 0px 1px 6px 2px rgba(34, 60, 80, 0.2);
      box-shadow: 0px 1px 6px 2px rgba(34, 60, 80, 0.2);

      & + .offer__head-item {
        margin-left: 15px;
      }

      &--hidden {
        opacity: 0;
      }
    }
  }

  &__table {
    &-item {
      display: flex;
      margin-top: 15px;
    }

    &-cell {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-box-shadow: 0px 1px 6px 2px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 0px 1px 6px 2px rgba(34, 60, 80, 0.2);
      box-shadow: 0px 1px 6px 2px rgba(34, 60, 80, 0.2);

      & + .offer__table-cell {
        margin-left: 15px;
      }

      &--button {
        box-shadow: none;
        background-color: transparent;
      }
    }
  }
}

.offer-edit {
  padding: 20px;

  .button,
  .input + .input {
    margin-top: 15px;
  }
}
