.check-domain {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 10px;

  button {
    border-radius: 4px;
    border: none;
    padding: 5px 15px;
    background-color: #81d600;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    min-height: 35px;

    transition: all 0.3s linear;

    &:hover {
      box-shadow: 0 0 3px #81d600;
    }
  }

  .status {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 700;
    color: red;

    &--success {
      color: #81d600;
    }
  }
}

.button-loading {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #fff;

  animation: loading 1s infinite linear;

  @keyframes loading {
    0% {
      transform: rotateY(0);
    }

    100% {
      transform: rotateY(360deg);
    }
  }
}
