.modal {
  display: none;
  position: fixed;
  z-index: 15;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;

  &--open {
    display: flex !important;
  }

  .modal__overlay {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background-color: #8b8b8b;
    opacity: 0.9;
    display: block;
    z-index: 15;
  }

  .modal__body {
    position: relative;
    z-index: 20;
    width: 34.4rem;
    background-color: #fff;
  }
}
