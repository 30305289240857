.boolean-marker {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: auto 0;

  &--truthy {
    background-color: #00be00;
  }

  &--falsy {
    background-color: red;
  }
}
