@use 'sass:map';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100;400;500;700&display=swap');
@import '../../styles/variables.scss';

.input {
  width: 100%;
  font-family: 'Noto Sans SC', sans-serif;
  position: relative;
  padding-top: 17px;

  .control-wrap {
    position: relative;
  }

  .control {
    width: 100%;
    position: relative;
    padding: 7px 15px;
    border-radius: 4px;
    font-size: 12px;
    border: 1px solid map-get($dark, '400');
    color: map-get($dark, '800');
    background-color: map-get($dark, '100');
    outline: none;
    font-weight: 400;
    box-sizing: border-box;
    transition: background-color 0.3s linear;

    &::placeholder {
      font-size: 12px;
      font-weight: 400;
    }

    &:focus {
      background-color: transparent;
    }

    &:not(:placeholder-shown),
    &:focus {
      &::placeholder {
        color: transparent;
      }

      & + .input-placeholder {
        opacity: 1;
      }
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button,
    &[type='number'] {
      -webkit-appearance: none;
      -moz-appearance: textfield;
      margin: 0;
    }
  }

  &--primary {
    .control {
      border: 1px solid map-get($primary, '300');
      background-color: map-get($primary, '200');
      color: map-get($light, '500');

      &::placeholder {
        color: map-get($light, '500');
      }

      &:focus {
        background-color: map-get($primary, '500');
        box-sizing: border-box;
      }
    }

    .input-placeholder {
      color: map-get($primary, '500');
    }
  }

  &--secondary {
    .control {
      border: 1px solid map-get($secondary, '300');
      background-color: map-get($secondary, '200');
      color: map-get($primary, '700');

      &::placeholder {
        color: map-get($primary, '300');
      }

      &:focus {
        background-color: map-get($secondary, '500');
        box-sizing: border-box;
      }
    }

    .input-placeholder {
      color: map-get($primary, '500');
    }
  }

  &--error {
    .control {
      color: $error;
      border: 1px solid $error;
    }

    .input__error {
      margin: 2px 0 0 5px;
      color: $error;
      font-size: 10px;
    }

    .input-status {
      background-color: $error;
    }
  }

  &-placeholder {
    position: absolute;
    opacity: 0;
    top: -22px;
    left: 5px;
    font-size: 13px;
    user-select: none;
    transition: all 0.3s linear;
  }

  &--loading {
    .control {
      background-color: map-get($dark, '400');
    }

    &.input--primary {
      .control {
        background-color: map-get($primary, '100');
      }
    }

    &.input--secondary {
      .control {
        background-color: map-get($secondary, '100');
      }
    }

    .input-status {
      animation: 2.5s linear 0s normal none infinite running loading;
      -webkit-animation: 2.5s linear 0s normal none infinite running loading;

      @keyframes loading {
        0% {
          transform: rotateY(0deg) translateY(-50%);
        }

        100% {
          transform: rotateY(360deg) translateY(-50%);
        }
      }
    }
  }

  &--success {
    .control {
      border-color: $success;
    }

    .input-status {
      background-color: $success;
    }
  }

  &-status {
    position: absolute;
    top: calc(50% - 6px);
    left: 95%;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: translateY(-50%);
    background-color: map-get($primary, '500');
  }
}
