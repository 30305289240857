.current {
  &__info {
    display: flex;
    flex-wrap: wrap;

    &-item {
      box-shadow: 0 0 5px #ccc;
      padding: 10px;
      margin: 10px 0 0 10px;
      border-radius: 5px;

      &-title {
        font-size: 18px;
        font-weight: 700;
      }
    }
  }

  &__buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }

  &__select {
    margin-left: auto;
    width: 40%;

    &--limit {
      width: 5%;
      margin-left: 0;
      margin-right: 20px;
    }
  }

  &__limit {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  &__button {
    padding: 5px 30px;
    border: none;
    margin-right: 20px;
    font-size: 18px;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid #000;
    transition: all 0.3s linear;

    &--active {
      background-color: #000;
      color: #fff;
      font-weight: 700;

      &.current__button--abusers {
        background-color: #ff4343;
        border: 1px solid #ff4343;
        color: #fff;
      }
    }

    &:hover {
      box-shadow: 0 0 3px #000;
    }
  }

  .pagination {
    display: flex;
    margin-top: 20px;
    margin-right: 20px;

    & > li {
      margin-left: 10px;
      background-color: transparent;
      border: 1px solid #bbb;
      border-radius: 5px;

      &:first-child {
        margin-left: 0;
      }

      & > a {
        padding: 15px;
      }

      &.selected {
        background-color: blue;
        color: #fff;
      }
    }
  }

  .input {
    margin-top: -15px;
    width: 150px;
    margin: -15px 10px 0 10px;
  }
}
