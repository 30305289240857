.images-page {
  &__list {
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    margin-left: -15px;

    &-item {
      position: relative;
      flex: 1 1 calc((100% / 4) - 15px);
      max-width: calc((100% / 8) - 15px);
      margin: 15px 0 0 15px;

      img {
        width: 100%;
        height: auto;
        border-radius: 5px;
        object-fit: cover;
      }

      &:hover {
        transform: scale(1.05);

        &::before {
          content: "";
          width: 100%;
          height: 100%;
          display: block;
          position: absolute;
          background-color: #ff515177;
          border-radius: 5px;
          background-image: url(./assets/trash.svg);
          background-size: 40px;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }
  }
}
