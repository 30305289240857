.fields {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;

  @for $i from 1 to 5 {
    &--row-#{$i} {
      .fields__item {
        flex: 1 1 calc((100% / #{$i}) - 10px);
      }
    }
  }

  &__item {
    flex: 1 1 100%;
    margin: 10px 0 0 10px;
  }
}
