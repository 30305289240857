@use 'sass:map';
@import "../../styles/variables.scss";

.badge {
  border-radius: 5px;
  box-shadow: none;

  &--primary {
    background-color: map-get($primary, "100");
  }

  &--secondary {
    background-color: map-get($secondary, "800");
  }

  &--dangerous {
    background-color: $error;
  }

  &--small {
    padding: 10px;
    font-size: 13px;
  }

  &--medium {
    padding: 10px 13px;
    font-size: 16px;
  }

  &--big {
    padding: 12px 15px;
    font-size: 18px;
  }
}
