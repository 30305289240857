$success: #18ba92;
$error: #d66464;

$primary: (
  "100": #99b1ff,
  "200": #809eff,
  "300": #668aff,
  "400": #4d77ff,
  "500": #3363ff,
  "600": #1a50ff,
  "700": #003cff,
  "800": #0036e6,
  "900": #0030cc,
);

$secondary: (
  "100": #a5f6e2,
  "200": #8ff4db,
  "300": #78f2d3,
  "400": #62f0cc,
  "500": #4bedc5,
  "600": #35ebbd,
  "700": #1ee9b6,
  "800": #1bd2a4,
  "900": #18ba92,
);

$dark: (
  "100": #eaf2f5,
  "200": #d6e4ec,
  "300": #aabbc6,
  "400": #e8e8ea,
  "500": #343c44,
  "600": #262f3a,
  "700": #1a2330,
  "800": #101827,
  "900": #091020,
);

$light: (
  "100": #fefefe,
  "200": #fdfdfd,
  "300": #fbfbfb,
  "400": #f7f7f7,
  "500": #f3f3f3,
);
