.locations {
  &-add {
    width: 800px;
    margin-bottom: 30px;

    display: flex;
    align-items: flex-end;

    &__select {
      width: 400px;
      margin: 0 20px;
    }
  }
}
