.streams {
  &__head {
    display: flex;

    & > div {
      flex: 1;
      border-radius: 4px;
      box-shadow: 0 0 3px #ccc;
      text-align: center;

      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }

  &__list {
    margin-top: 20px;

    &-item {
      display: flex;
      margin-bottom: 15px;

      & > div {
        flex: 1;
        border-radius: 4px;
        box-shadow: 0 0 3px #ccc;
        text-align: center;
        word-break: break-all;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;

        &:not(:first-child) {
          margin-left: 10px;
        }

        &.button-cell {
          box-shadow: none;
        }
      }
    }
  }

  &__button {
    border-radius: 4px;
    color: #fff;
    padding: 5px 15px;
    font-size: 12px;
    background-color: blue;
    border: none;

    &--delete {
      background-color: red;
    }
  }
}

.add-form {
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-height: 900px;
  min-height: 400px;

  .input {
    margin-top: 15px;
  }

  label {
    margin-top: 15px;
  }

  .key-item {
    display: flex;
    margin-top: 40px;

    &__select {
      width: 50%;
      margin: 0 10px;
    }
  }

  &__button {
    margin-top: 20px;
  }
}
