.add-geo {
  position: relative;
  margin-left: auto;

  &__dropdown {
    width: 400px;
    height: 0;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 0 2px #000;
    border-radius: 5px;
    top: calc(100% + 10px);
    transition: height 0.3s linear;
    overflow: hidden;

    &--active {
      height: auto;
      overflow: visible;
    }

    .create__geos {
      margin-top: 20px;
    }

    form {
      padding: 15px;
      display: flex;
      flex-direction: column;
    }

    .input + .input {
      margin-top: 5px;
    }

    &-visible {
      margin-top: 20px;

      input {
        margin-left: 20px;
      }
    }
  }
}
