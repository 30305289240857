.header {
  padding: 15px 0;
  border-bottom: 1px solid #6767f6;
  margin-bottom: 30px;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__nav {
    flex-grow: 0.8;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    padding: 0 10px;
    margin-right: 30px;

    &::-webkit-scrollbar {
      height: 0;
    }

    &-link {
      padding: 7px 10px;
      border: 1px solid #6767f6;
      color: #6767f6;
      border-radius: 5px;
      font-size: 12px;
      white-space: nowrap;

      &.active {
        background-color: #6767f6;
        color: #fff;
        font-weight: 700;
      }

      &:hover {
        box-shadow: 0 0 3px #6767f6;
      }

      &:not(:first-child) {
        margin-left: 15px;
      }
    }
  }

  &__cloak-geo,
  &__cloak-num {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: -20px;

    &-title {
      font-weight: 700;
      font-size: 13px;
    }

    &-select {
      width: 170px;
      min-width: 170px;
    }
  }
}
