.login {
  width: 500px;
  margin: 100px auto 0 auto;

  &__form {
    text-align: center;
    display: flex;
    flex-direction: column;

    .input + .input {
      margin-top: 7px;
    }
  }

  &__button {
    margin-top: 20px;
  }
}
