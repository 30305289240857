.filters {
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;

  &__select {
    width: 30%;
    margin-right: 30px;
  }

  &__create-button {
    transition: all 0.3s linear;
    margin-left: auto;

    &--exit {
      background-color: red;
      font-size: 13px;
      padding: 5px 20px;

      &:hover {
        box-shadow: 0 0 3px red !important;
      }
    }
  }
}
