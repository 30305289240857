.add-domain {
  position: relative;
  z-index: 10;

  &__dropdown {
    width: 300px;
    height: 0;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 0 2px #000;
    border-radius: 5px;
    top: calc(100% + 10px);
    transition: height 0.3s linear;
    overflow: hidden;

    &--active {
      height: 230px;
      overflow: visible;
    }

    form {
      padding: 15px;
      display: flex;
      flex-direction: column;

      .input {
        margin-bottom: 20px;
      }
    }
  }

  button {
    &[type="submit"] {
      margin-top: 20px;
    }
  }
}
