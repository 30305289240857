.affilates {
  &__list {
    margin-top: 20px;

    &-head {
      display: flex;

      & > div {
        flex: 1;
        text-align: center;
        word-break: break-word;
      }
    }

    &-body {
      & > a,
      & > div {
        display: flex;
        align-items: center;
        box-shadow: 0 0 3px #bbb;
        width: 100%;
        margin-top: 20px;

        & > div {
          flex: 1;
          text-align: center;
        }

        .affilates__list-body-counter {
          display: flex;

          & > div {
            padding-left: 10px;

            & + div {
              border-left: 2px solid #ccc;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

.current-affilate {
  &__tabs {
    display: flex;

    &-item {
      padding: 5px 10px;
      border: 1px solid #6767f6;
      background-color: transparent;
      border-radius: 5px;
      cursor: pointer;

      & + .current-affilate__tabs-item {
        margin-left: 15px;
      }

      &.active {
        background-color: #6767f6;
        font-weight: 700;
        color: #fff;
      }
    }
  }
}
