.main-table {
  margin-top: 15px;

  &__active {
    position: relative;
    display: block;
    width: 70px;
    height: 30px;
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 20px;
    cursor: pointer;

    span {
      width: 25px;
      height: 25px;
      display: block;
      background-color: #ccc;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 1px;
      transform: translateY(-50%);
      transition: all 0.2s linear;
    }

    input[type='checkbox']:checked + span {
      left: calc(100% - 26px);
      background-color: #4fc04f;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    & > div {
      flex: 1;
      font-size: 15px;
      font-weight: 700;
      padding: 10px;
      border-radius: 5px;
      box-shadow: 0 0 3px #000;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      &:not(:first-child) {
        margin-left: 10px;
      }

      &:nth-child(1),
      &:nth-child(2),
      &:last-child {
        visibility: hidden;
        box-shadow: none;
        background-color: transparent;
      }
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    background-color: transparent;

    &-geos {
      flex-direction: column;
    }

    & > a,
    & > div {
      box-shadow: 0 0 5px #ccc;
      background-color: #fff;
      padding: 15px 0;
      font-size: 16px;
      text-align: center;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      color: #000;

      .main-table__item-block {
        padding: 0 20px;

        &:not(:last-child) {
          border-right: 2px solid #ccc;
        }

        &:nth-child(2) {
          color: red;
        }
      }

      &:not(:first-child) {
        margin-left: 10px;
      }

      &:nth-child(1),
      &:nth-child(2),
      &:last-child {
        box-shadow: none;
        background-color: transparent;
      }
    }

    .main-table__item-history {
      position: relative;
      overflow: visible;

      &:hover {
        .main-table__item-history-value {
          opacity: 1;
          visibility: visible;
        }
      }

      &-preview {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 120px;
        padding: 0 10px;
        font-size: 14px;
      }

      &-value {
        width: 200%;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        font-size: 16px;
        transition: opacity 0.3s linear;
        top: 100%;
        left: -100%;
        background-color: #fff;
        box-shadow: 0 0 3px #ccc;
        border-radius: 4px;
        padding: 5px;
        white-space: pre-wrap;

        z-index: 20;
      }
    }

    .main-table__item-keywords {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      font-size: 14px;

      & > div {
        margin-left: 5px;
      }
    }

    .main-table__item-flags {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      &-item {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: red;
        margin: auto 0;

        & + .main-table__item-flags-item {
          margin-left: 5px;
        }

        &--true {
          background-color: #00be00;
        }
      }
    }

    .main-table__link {
      text-decoration: none;
      color: #fff;
      background-color: #000;
      padding: 5px 14px;
      border-radius: 5px;
      font-size: 14px;

      &--white {
        color: #000;
        background-color: #fff;
        box-shadow: 0 0 3px #ccc;
      }
    }

    &-edit {
      background-color: #00be00;
      padding: 5px 15px;
      color: #fff;
      border: none;
      border-radius: 5px;
      font-size: 13px;

      &:hover {
        box-shadow: 0 0 3px #00be00;
      }
    }
  }

  .current__select--limit {
    margin-left: 15px;
  }
}

.pagination {
  display: flex;
  margin-top: 20px;

  & > li {
    margin-left: 10px;
    background-color: transparent;
    border: 1px solid #bbb;
    border-radius: 5px;

    & > a {
      padding: 15px;
    }

    &.selected {
      background-color: blue;
      color: #fff;
    }
  }
}

.dataTables_wrapper {
  max-width: 100%;
  max-height: 400px;
  overflow-x: scroll;
  overflow-y: scroll;
  margin-top: 25px;
}

thead[data-test='table-foot'] {
  display: none;
}

.dataTable {
  th {
    padding-right: 5px !important;
    padding-left: 18px !important;
    text-align: start;

    &:not(:last-child) {
      border-right: 2px solid #dee2e6;
    }

    &:before,
    &:after {
      left: 5px;
      right: 0;
      text-align: start;
    }
  }
}
