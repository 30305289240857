.delete-confirm {
  padding: 20px;

  h2 {
    text-align: center;
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__button {
    background-color: #00be00;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 7px 35px;
    transition: all 0.3s linear;
    margin-top: 20px;

    &:nth-child(1) {
      padding: 2px 18px;
      font-size: 13px;
      background-color: red;
      margin-left: 0;

      &:hover {
        box-shadow: 0 0 3px red;
      }
    }

    &:hover {
      box-shadow: 0 0 3px red;
    }
  }
}
