.users {
  &__second-table {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px;
      border: 2px solid #ccc;
      border-radius: 5px;
      margin: 5px 0 0 5px;
    }
  }

  &__filters {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .date-filter {
      &__select {
        margin: 0;
      }

      .picker,
      .react-datetimerange-picker {
        margin-right: 10px;
      }
    }
  }

  &__tabs {
    width: 1200px;
    display: flex;
    align-items: center;

    .current__select {
      max-width: 200px;
      margin-left: 15px;
    }

    &-item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: 1px solid #6767f6;
      border-radius: 5px;
      cursor: pointer;
      padding: 5px 10px;

      &--active {
        background-color: #6767f6;
        color: #fff;
        font-weight: 700;
      }

      & + .users__tabs-item {
        margin-left: 15px;
      }
    }
  }

  &__list {
    margin-top: 20px;
    padding: 10px;
    overflow: auto;
    max-height: 500px;

    &--big {
      .users__list-head {
        &-item {
          min-width: 190px;
        }
      }

      .users__list-body {
        &-item {
          & > div {
            min-width: 190px;
            word-break: break-all;
          }
        }
      }
    }

    &--medium {
      .users__list-head {
        &-item {
          min-width: 160px;
        }
      }

      .users__list-body {
        &-item {
          & > div {
            min-width: 160px;
          }
          word-break: break-all;
        }
      }
    }

    &-head {
      display: flex;

      &-item {
        flex: 1;
        border-radius: 4px;
        box-shadow: 0 0 3px #bbb;
        text-align: center;

        & + .users__list-head-item {
          margin-left: 10px;
        }
      }
    }

    &-body {
      &-item {
        display: flex;
        width: 100%;
        margin-top: 15px;

        & > div {
          flex: 1;
          border-radius: 4px;
          box-shadow: 0 0 3px #bbb;
          display: flex;
          align-items: center;
          justify-content: center;

          & + div {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
